const Facebook = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="currentColor" />
      <g clipPath="url(#clip0_4279_1270)">
        <path
          d="M29.9999 19.9943C29.9999 14.4684 25.5203 9.98877 19.9943 9.98877C14.4684 9.98877 9.98877 14.4684 9.98877 19.9943C9.98877 24.9883 13.6476 29.1278 18.431 29.8784V22.8866H15.8905V19.9943H18.431V17.79C18.431 15.2823 19.9248 13.8972 22.2102 13.8972C23.3046 13.8972 24.45 14.0926 24.45 14.0926V16.5549H23.1883C21.9454 16.5549 21.5577 17.3263 21.5577 18.1183V19.9943H24.3327L23.8891 22.8866H21.5577V29.8784C26.341 29.1278 29.9999 24.9883 29.9999 19.9943Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_4279_1270">
          <rect
            width="20.0111"
            height="20.0111"
            fill="white"
            transform="translate(9.98877 9.98877)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Facebook
